require('./legalTextBlock.scss');

// @vendor
const React = require('react');
const PropTypes = require('prop-types');

class LegalTextBlock extends React.Component {
    constructor(props) {
        super(props);
        this.legalBlockRef = React.createRef();
    }
    
    checkIfScroll(legalTextBlock) {
        return legalTextBlock.current.scrollHeight > legalTextBlock.current.clientHeight;
    }

    componentDidMount() {
        this.initIntersectionObserver();
        !this.checkIfScroll(this.legalBlockRef) && this.props.onTextRead && this.props.onTextRead();
    }

    componentWillUnmount(){
        if(this.intersectionObserver){
            this.intersectionObserver.disconnect()
        }
    }

    componentDidUpdate() {
        !this.checkIfScroll(this.legalBlockRef) && this.props.onTextRead && this.props.onTextRead();
    }
    

    initIntersectionObserver = () => {
        const options = {
            root: this.legalBlockRef.current,
            treshold: 1.0,
        }
        this.intersectionObserver = new IntersectionObserver((entries) =>{
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    this.props.onTextRead()
                }
            });
        },options)
        const lastElement = this.legalBlockRef.current.lastElementChild;
        this.intersectionObserver.observe(lastElement)

    }
    
    render() {
        const { textContent, alternativeHeight } = this.props;
        return(
            <div 
                className="legal-text-block" 
                ref={this.legalBlockRef}
                style={alternativeHeight ? { height: alternativeHeight } : undefined}
            >
                {textContent}
                <div className='legal-text-block__bottom-element' />
            </div>
        );
    }
}

LegalTextBlock.propTypes = {
    alternativeHeight: PropTypes.string,
    textContent: PropTypes.object,
    onTextRead: PropTypes.func,
};

LegalTextBlock.defaultProps = {
    onTextRead: null,
    alternativeHeight: '',
}

module.exports = LegalTextBlock;