export const SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM = 'SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM';
export const SAVINGS_WITHOUT_CONDITION_WITH_TERM = 'SAVINGS_WITHOUT_CONDITION_WITH_TERM';
export const SAVINGS_WITH_CONDITION_WITH_TERM = 'SAVINGS_WITH_CONDITION_WITH_TERM';
export const SAVINGS_WITH_CONDITION_WITHOUT_TERM = 'SAVINGS_WITH_CONDITION_WITHOUT_TERM'
export const SAVING_ACCOUNTS_FLOWS = [
    SAVINGS_WITHOUT_CONDITION_WITHOUT_TERM,
    SAVINGS_WITHOUT_CONDITION_WITH_TERM,
    SAVINGS_WITH_CONDITION_WITHOUT_TERM,
    SAVINGS_WITH_CONDITION_WITH_TERM,
];
export const BASE_IMG_URL = 'https://www.openbank.es/assets/static/private-assets/images/commercial-banners/';
export const SAVINGS_ACCOUNT_IMG = 'cuenta_ahorro.jpg';
export const SAVINGS_ACCOUNT_PASSPORT_IMG = 'cuenta_ahorro_pasaporte.jpg';
export const SAVINGS_ACCOUNT_ID = 'cuenta_ahorro_open';
export const SAVINGS_ACCOUNT_HIRING_FLOW_URL = '/myprofile/purchase/e-account-loan/';
