module.exports = {
    ACCOUNT_OVERVIEW: "ACCOUNT_OVERVIEW",
    ACCOUNTS_DEBITS: "ACCOUNTS_DEBITS",
    BIOCATCH_EXCEPTION_STATUS_CODE: 203,
    CARDS_PAGE: "CARDS_PAGE",
    CHALLENGE_PAGE: "CHALLENGE_PAGE",
    CHANGE_ADDRESS: "CHANGE_ADDRESS",
    CHANGE_CES: "CHANGE_CES",
    CHANGE_EMAIL: "CHANGE_EMAIL",
    CHANGE_PHONE: "CHANGE_PHONE",
    CONFIRMATION_PAGE: "CONFIRMATION_PAGE",
    DEFAULT: "DEFAULT",
    DEPOSITS_PAGE: "DEPOSITS_PAGE",
    FREQUENT_BENEFICIARY_TRANSFER: "FREQUENT_BENEFICIARY_TRANSFER",
    INVESTMENTS_PAGE: "INVESTMENTS_PAGE",
    KNOWN_ACCOUNT_TRANSFER: "KNOWN_ACCOUNT_TRANSFER",
    LOANS_MORTAGES: "LOANS_MORTAGES",
    MAKE_TRANSFER: "MAKE_TRANSFER",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    NEW_BENEFICIARY_PAGE: "NEW_BENEFICIARY_PAGE",
    NEW_BENEFICIARY_TRANSFER: "NEW_BENEFICIARY_TRANSFER",
    ONG_TRANSFER: "ONG_TRANSFER",
    OPEN_CC: "OPEN_CC",
    OPEN_NEW_CARD: "OPEN_NEW_CARD",
    PERSONAL_DETAILS: "PERSONAL_DETAILS",
    REUSE_TRANSFER: "REUSE_TRANSFER",
    SECURITY_SETTINGS_PAGE: "SECURITY_SETTINGS_PAGE",
    SECURITY_KEYS_PAGE: "SECURITY_KEYS_PAGE",
    SESSION_EXPIRED: "SESSION_EXPIRED",
    SUCCESSFUL_CHANGE: "SUCCESSFUL_DETAILS_CHANGE",
    SUCCESSFUL_PAYMENT: "SUCCESSFUL_PAYMENT",
    SUCCESSFUL_OPERATION: "SUCCESFUL_OPERATION",
    THIRD_PARTY_PROVIDER: "THIRD_PARTY_PROVIDER",
    TRANSFER_BETWEEN_MYACCOUNTS: "TRANSFER_BETWEEN_MyACCOUNTS",
    TRANSFERS_PAGE: "TRANSFERS_PAGE",
    UNSUCCESFUL_OPERATION: "UNSUCCESFUL_OPERATION",
    WRONG_CONFIRM_CODE: "WRONG_CONFIRM_CODE",
    VIEW_CES: "VIEW_CES",
    HIGH_RISK: "HIGH_RISK",
    NO_VALUE: "NO_VALUE",
};
